import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            Shopkeyp 2023. Developed by <a href="https://github.com/rajayne">Ryann Nealey</a> for USF's Software Engineering Bootcamp
        </div>
    );
}

export default Footer;